import { Neutral, Positive } from '../Core/Button';
import { memo, useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Dialog from '../Dialog';
import { CheckboxField, TextField } from '../Core';
import classNames from 'classnames';

export function BulkAddOptionsDisplay({
  initOptions = [],
  initLabels = [],
  setOptions = (options: string[], labels: (string | null)[]) => {},
  allowLabelValues = true,
  setShow = (_: boolean) => {}
}: any) {
  const [optionStr, setOptionStr] = useState('');
  const [handleLabelValues, setHandleLabelValues] = useState(
    initLabels.some((label: any) => label)
  );

  useEffect(() => {
    const curOptStr = initOptions
      .map((opt: string, index: number) => {
        const label = initLabels[index];
        if (label) opt = `${opt}::${label}`;
        return opt;
      })
      .join('\n');
    setOptionStr(curOptStr);
  }, []);

  return (
    <>
      <div className={styles.bulkUploadSubtitle}>
        Separate options using line breaks.{' '}
        {handleLabelValues &&
          'Separate labels and values with two colons (::).'}
      </div>
      {allowLabelValues && (
        <CheckboxField
          className={styles.bulkUploadValueToggle}
          checked={handleLabelValues}
          text='Add options with separate labels and values'
          onChange={(isChecked) => setHandleLabelValues(isChecked)}
        />
      )}
      <TextField
        type='textarea'
        rows={10}
        className={styles.bulkUploadInput}
        placeholder={
          handleLabelValues
            ? 'Label1:Value1\nLabel2:Value2\nLabel3:Value3'
            : 'Add\nOptions\nLike\nThis'
        }
        value={optionStr}
        onChange={(value: string) => setOptionStr(value)}
      />
      <div className='dialog-form-action text-center'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={() => {
            let options = optionStr
              .split('\n')
              .map((option) => option.trim())
              .filter((o) => o);
            const labels = options.map((opt) => {
              if (!handleLabelValues) return null;
              const parts = opt.split('::');
              return parts.length > 1 ? parts[1] : null;
            });
            if (handleLabelValues)
              options = options.map((opt) => {
                const parts = opt.split('::');
                return parts.length > 1 ? parts[0] : opt;
              });
            setOptions(options, labels);
            setShow(false);
          }}
        >
          Apply
        </Positive>
      </div>
    </>
  );
}

const BulkAddOptionsTrigger = ({
  initOptions = [],
  initLabels = [],
  setOptions = (options: string[], labels: (string | null)[]) => {},
  allowLabelValues = true,
  disabled = false
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='Bulk Edit Options'
      >
        <BulkAddOptionsDisplay
          initOptions={initOptions}
          initLabels={initLabels}
          setOptions={setOptions}
          allowLabelValues={allowLabelValues}
          setShow={setShow}
        />
      </Dialog>
      <div
        className={classNames(
          styles.bulkUploadTrigger,
          disabled && styles.bulkUploadTriggerDisabled
        )}
        onClick={() => !disabled && setShow(true)}
      >
        Bulk Edit
      </div>
    </>
  );
};

export default memo(BulkAddOptionsTrigger);
