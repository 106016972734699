import { ChangeEvent, useRef } from 'react';
import { useAppSelector } from '../../../hooks';
import modalStyles from '../../../components/Modals/styles.module.scss';
import { isValidEmail } from '../../../utils/validate';
import { parseCSV } from '../../../utils/documents';

type BulkInvite = {
  email: string;
  role: string;
  firstName?: string;
  lastName?: string;
  user_groups_ids?: string[];
  customAttributes: Record<string, string>;
};

type BulkInviteUploaderProps = {
  onBulkInvitesUpdate: (invites: BulkInvite[] | null) => void;
  onErrorUpdate: (error: string) => void;
  onFileUpdate: (file: File | null) => void;
};

const BulkInviteUploader = ({
  onBulkInvitesUpdate,
  onErrorUpdate,
  onFileUpdate
}: BulkInviteUploaderProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const newCustomAttributes = new Set<string>();

  const userGroups = useAppSelector(
    (state) => state.accounts.organization?.all_user_groups ?? []
  );

  const customAttributes: { id: string; label: string }[] = useAppSelector(
    (state) => state.accounts.organization?.custom_attributes ?? []
  );

  const labelToIdMap = Object.fromEntries(
    customAttributes.map((attr) => [attr.label.toLowerCase(), attr.id])
  );

  const handleCSVUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileUpdate(file);
      onErrorUpdate('');

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const payload = event.target?.result as string;
        const rows = parseCSV(payload);
        if (rows.length <= 1) {
          onErrorUpdate('This file has no users to invite');
          return;
        }

        const headers = rows[0];
        if (!headers.includes('Email') || !headers.includes('Role')) {
          onErrorUpdate('CSV file must include "Email" and "Role" columns');
          return;
        }

        const emailIndex = headers.indexOf('Email');
        const roleIndex = headers.indexOf('Role');
        const firstNameIndex = headers.indexOf('First Name');
        const lastNameIndex = headers.indexOf('Last Name');
        const userGroupsIndex = headers.indexOf('User Groups');
        const validRoles = ['Admin', 'Editor', 'Viewer'];

        const getGroupIdByName = (name: string): string | null => {
          const group = userGroups.find(
            (g: { name: string }) => g.name.toLowerCase() === name.toLowerCase()
          );
          return group ? group.id : null;
        };

        const parsedInvites = rows
          .slice(1)
          .map((row, index): BulkInvite | null => {
            const invite: BulkInvite = {
              email: row[emailIndex],
              role: row[roleIndex],
              firstName:
                firstNameIndex !== -1
                  ? row[firstNameIndex] || undefined
                  : undefined,
              lastName:
                lastNameIndex !== -1
                  ? row[lastNameIndex] || undefined
                  : undefined,
              customAttributes: {},
              user_groups_ids:
                userGroupsIndex !== -1
                  ? row[userGroupsIndex]
                      ?.split(',')
                      .map(getGroupIdByName)
                      .filter((id): id is string => id !== null)
                  : undefined
            };

            headers.forEach((header, i) => {
              if (
                i !== emailIndex &&
                i !== roleIndex &&
                i !== firstNameIndex &&
                i !== lastNameIndex &&
                i !== userGroupsIndex &&
                row[i]
              ) {
                const attributeId = labelToIdMap[header.toLowerCase()];
                if (attributeId) {
                  invite.customAttributes[attributeId] = row[i].trim();
                } else {
                  newCustomAttributes.add(header);
                  invite.customAttributes[header] = row[i].trim();
                }
              }
            });
            if (invite.email) {
              if (!isValidEmail(invite.email)) {
                onErrorUpdate(
                  `Invalid email "${invite.email}" at row ${index + 2}`
                );
                return null;
              }
            } else {
              return null;
            }

            if (!invite.role) {
              onErrorUpdate(
                `Missing role for email "${invite.email}" at row ${index + 2}`
              );
              return null;
            }

            if (
              !validRoles.some(
                (role) => role.toLowerCase() === invite.role.toLowerCase()
              )
            ) {
              onErrorUpdate(
                `Invalid role "${invite.role}" found. Valid roles are: Admin, Editor, Viewer`
              );
              return null;
            }

            return invite;
          })
          .filter((invite): invite is BulkInvite => invite !== null);

        if (parsedInvites.length === 0) {
          onErrorUpdate('No valid invites found in the CSV file');
          return;
        }

        onBulkInvitesUpdate(parsedInvites);
      });

      reader.addEventListener('error', () => {
        onErrorUpdate('Error reading file');
      });

      reader.readAsText(file);
    }
  };

  return (
    <>
      <div
        className={modalStyles.bulkInviteButton}
        onClick={() => fileInput.current?.click()}
      >
        Bulk CSV Invite
      </div>
      <input
        ref={fileInput}
        type='file'
        onChange={handleCSVUpload}
        accept='.csv'
        style={{
          position: 'absolute',
          bottom: 0,
          opacity: 0,
          zIndex: -1
        }}
      />
    </>
  );
};

export default BulkInviteUploader;
