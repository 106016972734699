import '../../../style/dialog-form.css';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from '../types';
import {
  CheckboxField,
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../../Core';
import { Button } from '../../Core/Button/button';
import IntegrationsSidebar from '../IntegrationsSidebar';
import useIntegrations from '../useIntegrations';
import { useParams } from 'react-router-dom';
import Combobox from '../../Core/Combobox';
import Label from '../../Dialog/Label';
import styles from '../styles.module.scss';
import UploadFieldMappingDialog from './components/UploadFieldMappingDialog';
import { FieldSelectorWithModal } from '../../Modals';
import { RolloutCredentialPicker } from '../../Modals/CreateAPIServiceModal/components/RolloutCredentialPicker';
import classNames from 'classnames';

const DEFAULT_SETTINGS = {
  username: '',
  password: '',
  form_ids: [],
  esign_send_type: '',
  esign_id_check: '',
  esign_hide_id_check: false,
  esign_read_only: false,
  esign_sobo_id: '',
  esign_additional_recipients: '',
  esign_subject: '',
  esign_message: '',
  joint_agreements: '',
  joint_agreements_field: '',
  static_fields: '',
  sf_mapping_file_name: '',
  sf_field_mapping: null,
  sf_rollout_credential: '',
  form_order_field: '',
  docusign_sf_record_type: '',
  docusign_sf_record: '',
  docusign_sf_record_status: '',
  docusign_sf_record_attach_file: '',
  force_test_mode: false
};

function QuikSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId,
    includeInactive: true
  });

  const [err, setErr] = useState('');

  const [quikConfig, setQuikConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  const [showUploadFieldMappingCSVModal, setShowUploadFieldMappingCSVModal] =
    useState(false);

  const setFields = (newData: Record<string, any>) =>
    setQuikConfig((quikConfig: any) => ({
      ...quikConfig,
      ...newData
    }));

  function onSubmitCustom(newIsActive: boolean) {
    if (
      newIsActive &&
      !(quikConfig.form_ids.length > 0 || quikConfig.form_order_field)
    ) {
      setErr(
        'Either the form order field or at least 1 Quik form ID is required'
      );
      return;
    }
    setErr('');

    return {
      isUpdate: !!integration?.data,
      secretMetadata: { ...quikConfig }
    };
  }

  return (
    <>
      {showUploadFieldMappingCSVModal && (
        <UploadFieldMappingDialog
          isOpen={showUploadFieldMappingCSVModal}
          setIsOpen={setShowUploadFieldMappingCSVModal}
          quikConfig={quikConfig}
          setQuikConfig={setQuikConfig}
        />
      )}
      <IntegrationsSidebar
        integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.QUIK]}
        onSubmitCustom={onSubmitCustom}
        customError={err}
      >
        <form className='integration-modal-form'>
          <Label>Authentication</Label>
          <div>
            <PropertyLabel label='Username' />
            <TextField
              value={quikConfig.username}
              placeholder='Default Test Account'
              onChange={(value: string) => setFields({ username: value })}
              className={styles.marginBottom}
            />
          </div>
          <div>
            <PropertyLabel label='Password' />
            <TextField
              value={quikConfig.password}
              placeholder='Default Test Account'
              onChange={(value: string) => setFields({ password: value })}
              type='password'
              className={styles.marginBottom}
            />
          </div>
          <div>
            <CheckboxField
              text={
                <>
                  Force draft mode{' '}
                  <InlineTooltip text='By default, Quik draft mode will only be enabled on the test Feathery form. When toggled, draft mode will also be enabled for the live form.' />
                </>
              }
              checked={quikConfig.force_test_mode}
              onChange={(isChecked) =>
                setFields({ force_test_mode: isChecked })
              }
              className={classNames('mb-5', 'mt-5')}
            />
          </div>
          <Label style={{ display: 'flex' }}>
            Quik Form IDs{' '}
            <InlineTooltip
              text={
                'A list of Quik form IDs corresponding to documents to fill. ' +
                "If ':<FieldId>' is appended to the Quik form ID, the form will only be included in the generated form bundle if the submission has a value for the corresponding Feathery field. "
              }
            />
          </Label>
          <div>
            <Combobox
              value={quikConfig.form_ids || []}
              onChange={(items: string[]) => {
                setFields({ form_ids: items });
              }}
              placeholder='132:IncludeIRAField::RepeatNumField'
              className={styles.marginBottom}
            />
          </div>
          <Label style={{ display: 'flex' }}>
            Static Field Values{' '}
            <InlineTooltip
              text={
                'A comma-separated list of key-value pairs separated by a colon. The key is the Quik field ID and the value is what you want to include in all filled Quik forms'
              }
            />
          </Label>
          <div>
            <TextField
              type='textarea'
              value={quikConfig.static_fields}
              placeholder='1own.FName:Bob,2own.FName:Chris'
              onChange={(value: string) => setFields({ static_fields: value })}
              className={styles.marginBottom}
              style={{ minHeight: '100px' }}
            />
          </div>
          <Label style={{ display: 'flex' }}>
            Dynamic Form Order & Prefill (Optional){' '}
            <InlineTooltip
              text={
                'The base format is [{"forms": [{"id": <Form ID>}]}], where <Form ID> is the ID of a Quik form to include. ' +
                'Each object in the array corresponds to a group of forms that will be filled with separate data. ' +
                'The forms will be included in order corresponding to their array order. ' +
                'If Salesforce is connected and you have a mapping uploaded, you may also include a `salesforce_prefill` property in the format ' +
                '[{"object": <Salesforce Object Type>, "id": <Salesforce Object ID>, "quik_entities": [<Quik Entity>]}] ' +
                'to specify a list of Salesforce objects to pull from and their corresponding Quik entities to map to.'
              }
            />
          </Label>
          <FieldSelectorWithModal
            selectId={quikConfig.form_order_field}
            selectType='hidden'
            placeholder='Select'
            onSelect={(data) => setFields({ form_order_field: data.selectId })}
            onlyHiddenFields
            style={{ marginBottom: '10px' }}
          />
          <CollapsibleSection
            title='E-Sign Pop-Up Configuration (Optional)'
            style={{ marginLeft: '-10px' }}
          >
            <div>
              <PropertyLabel
                label={
                  <>
                    Send Type
                    <InlineTooltip text='Set Quik E-Sign Pop-Up Send Type' />
                  </>
                }
              />
              <DropdownField
                onChange={(e: any) => {
                  setFields({ esign_send_type: e.target.value });
                }}
                selected={quikConfig.esign_send_type}
                options={[
                  { value: '', display: 'Unset' },
                  { value: 'EmailToSign', display: 'Email' },
                  { value: 'SendCopy', display: 'Send Copy' },
                  { value: 'InPersonSigner', display: 'In Person Signer' },
                  {
                    value: 'CertifiedDeliveries',
                    display: 'Certified Deliveries'
                  },
                  { value: 'Editor', display: 'Editor' }
                ]}
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Identity Check Type
                    <InlineTooltip text='Set Quik E-Sign Pop-Up Identity Check' />
                  </>
                }
              />
              <DropdownField
                onChange={(e: any) => {
                  setFields({ esign_id_check: e.target.value });
                }}
                selected={quikConfig.esign_id_check}
                options={[
                  { value: 'None', display: 'Unset' },
                  { value: 'SMS', display: 'SMS' },
                  { value: 'Phone', display: 'Phone' },
                  { value: 'LiveID', display: 'Live ID' },
                  { value: 'Facebook', display: 'Facebook' },
                  { value: 'LinkedIn', display: 'LinkedIn' },
                  { value: 'Google', display: 'Google' },
                  { value: 'Salesforce', display: 'Salesforce' },
                  { value: 'Twitter', display: 'Twitter' },
                  { value: 'Yahoo', display: 'Yahoo' },
                  { value: 'OpenID', display: 'OpenID' },
                  { value: 'IDCheck', display: 'ID Check' },
                  { value: 'NoIdentityCheck', display: 'No Identity Check' }
                ]}
                className={styles.marginBottom}
              />
            </div>
            <div>
              <CheckboxField
                text={
                  <>
                    Hide Identity Check Column
                    <InlineTooltip text='Hide the Identity Check column within the Quik E-Sign Pop-Up' />
                  </>
                }
                checked={quikConfig.esign_hide_id_check}
                onChange={(isChecked) =>
                  setFields({ esign_hide_id_check: isChecked })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <CheckboxField
                text={
                  <>
                    Set E-Sign Fields As Read Only
                    <InlineTooltip text='Set fields as read only within the Quik E-Sign Pop-Up' />
                  </>
                }
                checked={quikConfig.esign_read_only}
                onChange={(isChecked) =>
                  setFields({ esign_read_only: isChecked })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Docusign Joint Agreements
                    <InlineTooltip text='Envelopes will be sent to Docusign in draft mode so Joint Agreements can be enabled there' />
                  </>
                }
              />
              <DropdownField
                onChange={(e: any) => {
                  setFields({ joint_agreements: e.target.value });
                }}
                selected={quikConfig.joint_agreements}
                options={[
                  { value: '', display: 'No' },
                  { value: 'yes', display: 'Yes' },
                  { value: 'conditional', display: 'Conditional' }
                ]}
                className={styles.marginBottom}
              />
            </div>
            {quikConfig.joint_agreements === 'conditional' && (
              <div>
                <PropertyLabel
                  label={
                    <>
                      Joint Agreements Toggle
                      <InlineTooltip text='If the field value is truthy, Joint Agreements will be enabled' />
                    </>
                  }
                />
                <FieldSelectorWithModal
                  selectId={quikConfig.joint_agreements_field}
                  selectType='hidden'
                  placeholder='Select'
                  onSelect={(data) =>
                    setFields({ joint_agreements_field: data.selectId })
                  }
                  onlyHiddenFields
                  className='mb-4'
                />
              </div>
            )}
            <div>
              <PropertyLabel label='Send on Behalf Of' />
              <TextField
                value={quikConfig.esign_sobo_id}
                placeholder='Customer User ID'
                onChange={(value: string) =>
                  setFields({ esign_sobo_id: value })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Additional Recipients
                    <InlineTooltip text='Comma-separated list of additional non-signing recipients emails. Can also be a field ID for dynamic recipients.' />
                  </>
                }
              />
              <TextField
                value={quikConfig.esign_additional_recipients}
                placeholder='user@company.com'
                onChange={(value: string) =>
                  setFields({ esign_additional_recipients: value })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel label='Custom Email Invite Subject' />
              <TextField
                value={quikConfig.esign_subject}
                placeholder=''
                onChange={(value: string) =>
                  setFields({ esign_subject: value })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel label='Custom Email Invite Message' />
              <TextField
                value={quikConfig.esign_message}
                type='textarea'
                placeholder=''
                onChange={(value: string) =>
                  setFields({ esign_message: value })
                }
                className={styles.marginBottom}
              />
            </div>
          </CollapsibleSection>
          <CollapsibleSection
            title='Send Salesforce Data to Quik Forms'
            style={{ marginLeft: '-10px' }}
          >
            <div>
              <PropertyLabel
                label={
                  <>
                    Salesforce Account
                    <InlineTooltip
                      text={
                        'Pick the Salesforce account to use to send Salesforce data to Quik forms.'
                      }
                    />
                  </>
                }
              />
              <RolloutCredentialPicker
                app='salesforce'
                hideLabel
                credentialKey={quikConfig.sf_rollout_credential}
                onChange={(credential) => {
                  setFields({
                    sf_rollout_credential: credential?.credentialKey ?? ''
                  });
                }}
                className='mb-6'
              />
            </div>
            <div>
              <Button
                className='mb-4'
                variant='outline-primary'
                type='button'
                onClick={() => {
                  setShowUploadFieldMappingCSVModal(true);
                }}
              >
                {quikConfig.sf_field_mapping ? 'Update' : 'Upload'}
                {' Quik <> Salesforce Field Map'}
              </Button>
            </div>
          </CollapsibleSection>
          <CollapsibleSection
            title='Send Docusign Status or Envelope to Salesforce'
            expanded={false}
            style={{ marginLeft: '-10px' }}
          >
            <div>
              <PropertyLabel
                label={
                  <>
                    Salesforce Account
                    <InlineTooltip
                      text={
                        'Pick the Salesforce account to use to send Salesforce data to Quik forms.'
                      }
                    />
                  </>
                }
              />
              <RolloutCredentialPicker
                app='salesforce'
                hideLabel
                credentialKey={quikConfig.sf_rollout_credential}
                onChange={(credential) => {
                  setFields({
                    sf_rollout_credential: credential?.credentialKey ?? ''
                  });
                }}
                className='mb-4'
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Salesforce Record Type
                    <InlineTooltip text='The type of the Salesforce record to update (e.g. Case, Account)' />
                  </>
                }
              />
              <TextField
                value={quikConfig.docusign_sf_record_type}
                placeholder='Case'
                onChange={(value: string) =>
                  setFields({ docusign_sf_record_type: value })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Salesforce Record ID
                    <InlineTooltip text='The ID of the Salesforce record to update' />
                  </>
                }
              />
              <FieldSelectorWithModal
                selectId={quikConfig.docusign_sf_record}
                selectType='hidden'
                placeholder='Select'
                onSelect={(data) =>
                  setFields({ docusign_sf_record: data.selectId })
                }
                onlyHiddenFields
                className='mb-4'
              />
            </div>
            <div>
              <PropertyLabel
                label={
                  <>
                    Status Field
                    <InlineTooltip text='The field on the Salesforce record to update with the envelope status' />
                  </>
                }
              />
              <TextField
                value={quikConfig.docusign_sf_record_status}
                placeholder='Envelope Status'
                onChange={(value: string) =>
                  setFields({ docusign_sf_record_status: value })
                }
                className={styles.marginBottom}
              />
            </div>
            <div>
              <CheckboxField
                text='Attach signed envelope to Salesforce record'
                checked={quikConfig.docusign_sf_record_attach_file}
                onChange={(isChecked) =>
                  setFields({ docusign_sf_record_attach_file: isChecked })
                }
                className={classNames('mb-5', 'mt-5')}
              />
            </div>
          </CollapsibleSection>
        </form>
      </IntegrationsSidebar>
    </>
  );
}

export default QuikSettingsSidebar;
