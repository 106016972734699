import React, { ChangeEvent, useRef, useState } from 'react';
import Dialog from '../../../Dialog';
import Label from '../../../Dialog/Label';
import { Button } from '../../../Core/Button/button';
import { Positive } from '../../../Core/Button';
import { openTab } from '../../../../utils/domOperations';
import { InfoIcon } from '../../../Icons';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';
import useFeatheryRedux from '../../../../redux';
import { parseCSV } from '../../../../utils/documents';

type FieldPair = {
  sfField: string;
  quikField: string;
};

export type SfFieldMappingData = Record<string, FieldPair[]>;

interface UploadFieldMappingDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  quikConfig: any;
  setQuikConfig: (quikConfig: any) => void;
}

const UploadFieldMappingDialog: React.FC<UploadFieldMappingDialogProps> = ({
  isOpen,
  setIsOpen,
  quikConfig,
  setQuikConfig
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fieldMappingFile, setFieldMappingFile] = useState<File | null>(null);
  const [fieldMapFileName, setFieldMapFileName] = useState<string>(
    quikConfig?.sf_mapping_file_name || ''
  );
  const [error, setError] = useState('');

  const [fieldMappingData, setFieldMappingData] = useState<SfFieldMappingData>(
    {}
  );

  const {
    toasts: { addToast }
  } = useFeatheryRedux();

  const handleCSVUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setError('');

    setFieldMappingFile(file);
    setFieldMapFileName(file.name);

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const payload = event.target?.result as string;

      const rows = parseCSV(payload);

      if (rows.length <= 1) {
        setError('This file has no field mapping data to parse');
        return;
      }

      const headers = rows[0].map((header) => header.trim().toLowerCase());
      const findHeaderIndex = (substring: string) =>
        headers.findIndex((header) => header.includes(substring));

      const sfObjectIndex = findHeaderIndex('salesforce object');
      const sfFieldIndex = findHeaderIndex('salesforce field');
      const quikFieldIndex = findHeaderIndex('quik field');

      if (
        sfObjectIndex === -1 ||
        sfFieldIndex === -1 ||
        quikFieldIndex === -1
      ) {
        setError('This file has no field mapping data to parse');
        return;
      }

      const newData: SfFieldMappingData = {};
      rows.slice(1).forEach((row) => {
        const sfObject = row[sfObjectIndex]?.trim();
        const sfField = row[sfFieldIndex]?.trim();
        const quikField = row[quikFieldIndex]?.trim();

        if (!sfObject || !sfField || !quikField) {
          return;
        }

        if (!newData[sfObject]) newData[sfObject] = [];
        newData[sfObject].push({ sfField, quikField });
      });

      setFieldMappingData(newData);
    });

    reader.addEventListener('error', () => setError('Error reading file'));

    reader.readAsText(file);
  };

  const handleSubmit = async () => {
    setQuikConfig({
      ...quikConfig,
      sf_field_mapping: fieldMappingData,
      sf_mapping_file_name: fieldMapFileName
    });
    setIsOpen(false);
    addToast({
      text: 'Quik <> Salesforce field mapping updated. Press `Save Changes`.'
    });
  };

  return (
    <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className='text-center'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8
          }}
        >
          <h4>Upload Field Mapping CSV</h4>
          <button
            onClick={(e: any) => {
              e.preventDefault();
              openTab(
                'https://feathery-forms.webflow.io/integrations/quik#setup'
              );
            }}
            style={{
              color: 'var(--grey-70)',
              fontSize: '14px',
              fontWeight: 400,
              display: 'flex',
              gap: '4px',
              alignItems: 'center'
            }}
          >
            <Tooltip content='Click and see Automated Salesforce & Quik Field Mapping with Feathery section'>
              <div
                style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
              >
                <InfoIcon width={17} height={17} />
                <>View Documentation</>
              </div>
            </Tooltip>
          </button>
        </div>
        <div
          style={{
            paddingBlockStart: 24
          }}
        >
          <Label style={{ margin: 0 }}>
            {fieldMapFileName || 'No File Selected'}
          </Label>
          {error && (
            <p
              style={{
                color: 'var(--red-border)',
                paddingTop: 3,
                margin: 0
              }}
            >
              {error}
            </p>
          )}
        </div>
        <div
          className='dialog-form-action text-center'
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant='outline-primary'
            onClick={() => fileInput.current?.click()}
          >
            {fieldMapFileName ? 'Choose New CSV' : 'Choose CSV'}
          </Button>
          <input
            ref={fileInput}
            type='file'
            onChange={handleCSVUpload}
            accept='.csv'
            style={{
              position: 'absolute',
              bottom: 0,
              opacity: 0,
              zIndex: -1
            }}
          />
          {fieldMappingFile && (
            <Positive
              disabled={!Object.keys(fieldMappingData).length || !!error}
              onClick={handleSubmit}
            >
              Submit
            </Positive>
          )}
        </div>
        <div>
          <input
            ref={fileInput}
            type='file'
            onChange={handleCSVUpload}
            accept='csv'
            style={{
              position: 'absolute',
              bottom: 0,
              opacity: 0,
              zIndex: -1
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UploadFieldMappingDialog;
